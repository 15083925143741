<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dates"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        label="Период"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:prepend-inner="menu=!menu"
        outlined
        clearable
        hide-details
        rounded
        @click:clear="clearDates"
        class="rounded-lg"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="dates"
      range
      no-title
      :allowed-dates="allowedDates"
      :month-format="getMonthFormat"
      :header-date-format="getHeaderDateFormat"
      locale="ru-Ru"
      :weekday-format="weekDay"
      :first-day-of-week="1"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        Отмена
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="saveDates"
      > OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { zeroPadded } from '@/core/helpers';
import dateFormats from '@/mixins/dateFormats';

export default {
  name: 'DatePickerRange',
  mixins: [dateFormats],
  data() {
    return {
      dates: [],
      menu: false,
    };
  },
  props: {
    defaultDates: {
      type: Array,
      default: () => [],
    },
    blockedDays: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.dates = this.defaultDates;
  },
  watch: {
    dates(val) {
      if (val.length === 2) {
        if (new Date(val[0]) > new Date(val[1])) { this.dates = [val[1], val[0]]; }
      }
    },
  },
  computed: {
    dateRangeText: {
      get() {
        const dates = [];
        if (this.dates[0]) {
          const date = new Date(this.dates[0]);
          const dayFrom = `${zeroPadded(date.getDate())}.${zeroPadded(date.getMonth() + 1)}.${date.getFullYear()}`;
          dates.push(dayFrom);
          if (this.dates[1]) {
            const date = new Date(this.dates[1]);
            const dayTo = `${zeroPadded(date.getDate())}.${zeroPadded(date.getMonth() + 1)}.${date.getFullYear()}`;
            dates.push(dayTo);
          }
        }
        return dates.join(' ~ ');
      },
      set(val) {
        console.log(val);
      },
    },
  },

  methods: {
    weekDay(date) {
      return new Date(date).toLocaleString('ru-RU', { weekday: 'short' });
    },
    saveDates() {
      this.$refs.menu.save(this.dates);
      this.$emit('changeCurrentDates', this.dates);
    },
    clearDates() {
      this.dates = [];
      this.saveDates();
    },
    allowedDates(val) {
      if (!this.blockedDays) return true;
      const week = 518400000;
      if (
        new Date(this.dates[0]).getTime() > new Date(val).getTime() + week
        || new Date(this.dates[0]).getTime() + week < new Date(val).getTime()
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
</style>
