<template>
  <v-dialog
    :value="isSetClockDate"
    width="450"
    @click:outside="$emit('setClock', false)"
  >
    <v-card class="modal-container">
      <p class="modal__title mb-10">Установить часы</p>

      <div class="row mb-7">
        <div class="col-sm-12 py-0">
          <DatePickerRange @changeCurrentDates="changeCurrentDates" />
        </div>
      </div>

      <v-select
        :items="workTypes"
        label="выберите вид загрузки"
        outlined
        rounded
        class="rounded-lg"
        v-model="selectedType"
        item-value="id"
        item-text="title"
      ></v-select>

      <v-select
        :items="times"
        label="Часы"
        outlined
        rounded
        class="rounded-lg"
        v-model="selectedTime"
        v-if="selectedType === 4"
      ></v-select>

      <div class="card-actions d-flex justify-center">
        <v-btn
          color="black"
          class="mt-8 modal__btn"
          dark
          x-large
          @click="save"
        >Сохранить</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { workTypes } from '@/core/constants/workTypes';
import DatePickerRange from '@/components/UI/DatePickerRange.vue';

export default {
  name: 'SetClockModalDate',
  components: { DatePickerRange },
  data() {
    return {
      selectedTime: null,
      selectedType: 4,
      workTypes,
      dates: [],
    };
  },

  computed: {
    ...mapState(['times']),
  },

  methods: {
    save() {
      this.$emit('saveTimes', this.selectedTime);
    },

    changeCurrentDates(val) {
      this.dates = val;
    },
  },

  props: {
    isSetClockDate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.modal-container {
}
</style>
