<template>
  <v-dialog
    :value="isAddProjectModal"
    width="450"
    @click:outside="$emit('addProjectModal', false)"
  >
    <v-card class="modal-container">
      <p class="modal__title">Добавить проект</p>
      <div class="mt-6">
        <v-select
          :items="projects"
          label="Выберите проект"
          outlined
          rounded
          hide-details
          v-model="selectedProject"
          item-value="id"
          item-text="title"
          class="rounded-lg mb-5"
        ></v-select>

        <DatePickerRange @changeCurrentDates="changeCurrentDates" />

        <v-select
          :items="times"
          label="Часы"
          outlined
          rounded
          hide-details
          v-model="selectedTime"
          item-value="id"
          item-text="title"
          class="rounded-lg mt-5"
        ></v-select>

        <div class="card-actions d-flex justify-center">
          <v-btn
            color="black"
            dark
            class="modal__btn mt-10"
            x-large
            @click="save"
          >
            Сохранить
          </v-btn>
        </div>

      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import DatePickerRange from '@/components/UI/DatePickerRange.vue';

export default {
  name: 'AddProjectModal',

  data() {
    return {
      selectedProject: null,
      selectedTime: null,
      dates: [],
    };
  },

  computed: {
    ...mapState('projects', ['projects']),
    ...mapState(['times']),
  },

  methods: {
    save() {
      console.log(1);
    },
    changeCurrentDates(val) {
      this.dates = val;
    },
  },

  props: {
    isAddProjectModal: {
      type: Boolean,
      default: false,
    },
  },

  components: { DatePickerRange },
};
</script>

<style scoped>
</style>
