<template>
  <section class="fade">
    <div class="page-title-block">
      <div class="d-flex align-center">
        <v-icon
          class="mr-3 pointer"
          @click="$router.push('/staff')"
        >mdi-arrow-left</v-icon>
        <h3>Загруженность сотрудников</h3>
      </div>
    </div>
    <StaffWorkloadFilters
      @setClock="setClockModal"
      :selectedItems="selectedItems"
      @setFilterModal="setFilterModal"
    />

    <div class="mt-5">
      <div class="table-header">
        <div class="item-name rounded-tl-lg">
          <p>{{tableHeader.staff}}</p>
          <v-icon small>mdi-arrow-down</v-icon>
        </div>
        <div class="item-position d-flex justify-space-between">
          <p>{{tableHeader.position}}</p>
          <v-icon small>mdi-arrow-down</v-icon>
        </div>
        <div class="items-list">
          <div
            class="items-list__item"
            v-for="el in tableHeader.items"
            :key="el"
          >
            {{el}}
          </div>
        </div>
        <div class="item-total">
          <p>{{tableHeader.total}}</p>
        </div>
        <div class="item-deviation rounded-tr-lg">
          <p>{{tableHeader.deviation}}</p>
          <v-icon small>mdi-arrow-down</v-icon>
        </div>
      </div>

      <div class="table-body">
        <TableBodyItem
          v-for="el in users1"
          :key="el.id"
          :row="el"
          @choiceItem="choiceItem"
          @addProjectModal="addProjectModal"
          @setTime="setClockModalDate"
        />
      </div>
    </div>

    <SetClockModal
      :isSetClock="isSetClock"
      @setClock="setClockModal"
    />
    <SetClockModalDate
      :isSetClockDate="isSetClockDate"
      @setClock="setClockModalDate"
      @saveTimes="saveTimes"
    />

    <FilterModal
      :isFilterModal="isFilterModal"
      @setFilterModal="setFilterModal"
      :positions="positions"
    />
    <AddProjectModal
      :isAddProjectModal="isAddProjectModal"
      @addProjectModal="addProjectModal"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import StaffWorkloadFilters from '@/views/staff/components/StaffWorkloadFilters.vue';
import TableBodyItem from '@/components/TableBodyItem.vue';
import SetClockModal from '@/views/staff/components/SetClockModal.vue';
import SetClockModalDate from '@/views/staff/components/SetClockModalDate.vue';
import FilterModal from '@/views/staff/components/FilterModal.vue';
import AddProjectModal from '@/views/staff/components/AddProjectModal.vue';

export default {
  name: 'StaffWorkload',

  data() {
    return {
      isSetClock: false,
      isSetClockDate: false,
      isFilterModal: false,
      isAddProjectModal: false,
      largeRow: null,
      selectedItems: [],
    };
  },

  computed: {
    ...mapState('staff', ['tableHeader', 'users1', 'positions']),
  },

  methods: {
    ...mapActions('staff', ['getPositions']),
    setClockModal(val) {
      this.isSetClock = val;
    },
    setClockModalDate(val, projectId) {
      console.log(projectId);
      this.isSetClockDate = val;
    },
    setFilterModal(val) {
      this.isFilterModal = val;
    },
    choiceItem(val) {
      this.selectedItems.push(val);
    },
    saveTimes(selectedTime) {
      console.log(selectedTime);
    },
    addProjectModal(val, id) {
      if (id) {
        this.largeRow = id;
      }
      this.isAddProjectModal = val;
    },
  },

  components: {
    StaffWorkloadFilters,
    TableBodyItem,
    SetClockModal,
    FilterModal,
    AddProjectModal,
    SetClockModalDate,
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/styles/table';
* {
  font-size: 14px;
  line-height: 17px;
}
.table-header {
  width: 100%;
  max-width: 1100px;
  display: inline-flex;
  align-items: center;
  height: 50px;
  border-radius: 5px 5px 0 0;
  background-color: #ececec;
  border: 1px solid #757575;

  .item-name {
    padding: 0 10px;
  }

  div,
  p {
    font-size: 14px;
    font-weight: 500;
  }

  .items-list__item {
    font-size: 12px;
  }
}

.item-name,
.item-position,
.item-deviation {
  display: flex;
  justify-content: space-between;
  .v-icon {
    cursor: pointer;
  }
}
</style>
