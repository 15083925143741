<template>
  <v-dialog
    :value="isSetClock"
    width="540"
    @click:outside="$emit('setClock', false)"
  >
    <v-card class="modal-container">
      <p class="modal__title mb-10">Установить часы</p>

      <v-select
        :items="times"
        label="Часы"
        outlined
        rounded
        class="rounded-lg"
        v-model="selectedTime"
      ></v-select>

      <div class="card-actions d-flex justify-center">
        <v-btn
          color="black"
          class="mt-8 modal__btn"
          dark
          x-large
          @click="save"
        >Сохранить</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SetClockModal',
  data() {
    return {
      selectedTime: null,
      dates: [],
    };
  },

  computed: {
    ...mapState(['times']),
  },

  methods: {
    save() {
      this.$emit('saveTimes', this.selectedTime);
    },

    changeCurrentDates(val) {
      this.dates = val;
    },
  },

  props: {
    isSetClock: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
