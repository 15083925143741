export default {
  methods: {
    getMonthFormat(isoDate) {
      const m = isoDate.split('-')[1];
      return m === '01' ? 'Янв' : m === '02' ? 'Фев' : m === '03' ? 'Март' : m === '04' ? 'Апр'
        : m === '05' ? 'Май' : m === '06' ? 'Июнь' : m === '07' ? 'Июль' : m === '08' ? 'Авг'
          : m === '09' ? 'Сен' : m === '10' ? 'Окт' : m === '11' ? 'Ноя' : 'Дек';
    },
    getHeaderDateFormat(isoDate) {
      if (isoDate.length === 7) {
        const m = isoDate.split('-')[1];
        return m === '01' ? 'Январь' : m === '02' ? 'Февраль' : m === '03' ? 'Март' : m === '04' ? 'Апрель'
          : m === '05' ? 'Май' : m === '06' ? 'Июнь' : m === '07' ? 'Июль' : m === '08' ? 'Август'
            : m === '09' ? 'Сентябрь' : m === '10' ? 'Октябрь' : m === '11' ? 'Ноябрь' : 'Декабрь';
      } if (isoDate.length === 4) {
        return isoDate;
      }
    },
  },
};
