var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body-item"},[_c('div',{staticClass:"body-item-wrapper"},[_c('div',{staticClass:"item-name",class:{full: !_vm.isSubItem}},[_c('v-icon',{staticClass:"mr-3 chevron",style:({transform: _vm.isSubItem ? 'rotate(180deg)' : 'rotate(0deg)'}),on:{"click":function($event){_vm.isSubItem = !_vm.isSubItem}}},[_vm._v("mdi-chevron-down")]),_c('div',[_c('p',[_vm._v(_vm._s(_vm.row.name))]),_c('p',{staticClass:"user__outsourcing"},[_vm._v(_vm._s(_vm.row.outsourcing ? 'Аутстаф' : ''))])]),_c('v-menu',{key:"text",attrs:{"rounded":"lg","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","color":"red","dense":""},on:{"click":function($event){return _vm.addProject(true, _vm.row.id)}}},[_c('v-list-item-title',[_vm._v("Добавить проект")])],1)],1)],1),(_vm.row.outsourcing)?_c('div',{staticClass:"outsourcing"}):_vm._e()],1),_c('div',{staticClass:"item-position"},[_vm._v(_vm._s(_vm.row.position))]),_c('div',{staticClass:"items-list"},_vm._l((_vm.row.items),function(item){return _c('div',{key:item.title,staticClass:"items-list__item",class:{full: !_vm.isSubItem, over: item.value > 8, equel: item.value === 8,
           'day-off': item.dayOff},style:({background: item.value < 8 && item.value ? ("linear-gradient(to top,\n           #BDCBF0 " + (item.value*100/8) + "%, white " + (item.value*100/8) + "%)") : ''})},[(item.value)?_c('p',[_vm._v(_vm._s(item.value))]):(item.vacation)?_c('v-img',{attrs:{"src":_vm.vacationImg,"contain":"","max-width":"20px"}}):_vm._e()],1)}),0),_c('div',{staticClass:"item-total",class:{full: !_vm.isSubItem}},[_vm._v(_vm._s(_vm.row.total))]),_c('div',{staticClass:"item-deviation"},[_vm._v(_vm._s(_vm.row.deviation))])]),(_vm.isSubItem)?_vm._l((_vm.row.projects),function(project){return _c('div',{key:project.title,staticClass:"body-subitem"},[_c('div',{staticClass:"item-name item-name__sub"},[_c('p',[_vm._v(_vm._s(project.title))]),_c('v-menu',{key:"text",attrs:{"rounded":"lg","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
           var attrs = ref.attrs;
           var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","color":"red","dense":""},on:{"click":function($event){return _vm.$emit('setTime', true, project.title)}}},[_c('v-list-item-title',[_vm._v("Установить часы")])],1)],1)],1)],1),_c('div',{staticClass:"item-position"}),_c('div',{staticClass:"items-list"},_vm._l((project.items),function(prItem){return _c('div',{key:prItem.title,staticClass:"items-list__item",class:{selected: _vm.selectedItems.includes(prItem), 'day-off': prItem.dayOff},on:{"click":function($event){return _vm.choiceItem(prItem)}}},[(prItem.value)?_c('p',[_vm._v(_vm._s(prItem.value))]):(prItem.vacation)?_c('v-img',{attrs:{"src":_vm.vacationImg,"contain":"","max-width":"20px"}}):_vm._e()],1)}),0),_c('div',{staticClass:"item-total"},[_vm._v(_vm._s(project.total))]),_c('div',{staticClass:"item-deviation"},[_vm._v(_vm._s(project.deviation))])])}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }