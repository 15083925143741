<template>
  <v-dialog
    :value="isFilterModal"
    width="780"
    @click:outside="$emit('setFilterModal', false)"
  >
    <v-card class="modal-container">
      <p class="modal__title">Фильтр</p>
      <v-form
        ref="form"
        class="mt-9"
      >
        <div class="row mb-3">
          <div class="col-sm-6 py-0">
            <v-select
              :items="getUsersList"
              label="Выберите сотрудника"
              outlined
              rounded
              hide-details
              v-model="selectedUser"
              item-value="id"
              item-text="name"
              class="rounded-lg"
            ></v-select>
          </div>

          <div class="col-sm-6 py-0">
            <v-select
              :items="projects"
              label="Выберите проект"
              outlined
              rounded
              hide-details
              v-model="selectedProject"
              item-value="id"
              item-text="title"
              class="rounded-lg"
            ></v-select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 py-0">
            <v-select
              :items="positions"
              label="Выберите должность"
              outlined
              rounded
              hide-details
              v-model="selectedPosition"
              item-value="name"
              item-text="name"
              class="rounded-lg"
            ></v-select>
          </div>

          <div class="col-sm-6 py-0">
            <v-checkbox
              v-model="outsourcing"
              label="Аутстаф"
              required
              outlined
              rounded
            ></v-checkbox>
          </div>
        </div>

        <div class="card-actions d-flex justify-center">
          <v-btn
            color="black"
            dark
            class="modal__btn mt-10"
            x-large
            @click="save"
          >
            Сохранить
          </v-btn>
        </div>

      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'FilterModal',

  data() {
    return {
      selectedUser: null,
      selectedProject: null,
      selectedPosition: null,
      outsourcing: false,
    };
  },

  computed: {
    ...mapState('projects', ['projects']),
    ...mapGetters('staff', ['getUsersList']),
  },

  methods: {
    save() {
      console.log(1);
    },
  },

  props: {
    isFilterModal: {
      type: Boolean,
      default: false,
    },
    positions: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style scoped>
</style>
