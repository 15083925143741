<template>
  <div class="filters-list">
    <div class="date-picker mr-5">
      <DatePickerRange @changeCurrentDates="changeCurrentDates" />
    </div>

    <v-select
      :items="periods"
      class="mr-4 select rounded-lg"
      outlined
      hide-details
      v-model="selectedPeriod"
      rounded
      dense
    ></v-select>

    <v-btn
      icon
      class="ml-3"
      @click="$emit('setFilterModal', true)"
    >
      <v-icon>mdi-filter</v-icon>
    </v-btn>

    <v-btn
      text
      x-large
      @click="openAll"
      class="ml-auto mr-3 rounded-lg"
      rounded
    >Раскрыть все</v-btn>

    <v-btn
      outlined
      x-large
      @click="$emit('setClock', true)"
      rounded
      class="rounded-lg"
      :disabled="!selectedItems.length"
    >Установить часы</v-btn>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import DatePickerRange from '@/components/UI/DatePickerRange.vue';

export default {
  name: 'StaffWorkloadFilters',
  data() {
    return {
      dates: [],
      selectedPeriod: null,
      isOpenAll: false,
      periods: ['по дням', 'по неделям', 'по месяцам', 'по годам'],
    };
  },

  methods: {
    ...mapMutations('staff', ['toggleAllItem']),
    openAll() {
      this.toggleAllItem();
    },
    changeCurrentDates(val) {
      this.dates = val;
    },
  },

  mounted() {
    // eslint-disable-next-line prefer-destructuring
    this.selectedPeriod = this.periods[0];
  },

  props: {
    selectedItems: {
      type: Array,
    },
  },

  components: { DatePickerRange },
};
</script>

<style scoped lang="scss">
.filters-list {
  width: 100%;
  max-width: 1100px;
  display: flex;
  align-items: center;
  & .select {
    max-width: 200px;
  }
}

.date-picker {
  width: 300px;
}
</style>
