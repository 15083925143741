<template>
  <div class="body-item">
    <div class="body-item-wrapper">
      <div
        class="item-name"
        :class="{full: !isSubItem}"
      >
        <v-icon
          class="mr-3 chevron"
          @click="isSubItem = !isSubItem"
          :style="{transform: isSubItem ? 'rotate(180deg)' : 'rotate(0deg)'}"
        >mdi-chevron-down</v-icon>
        <div>
          <p>{{row.name}}</p>
          <p class="user__outsourcing">{{row.outsourcing ? 'Аутстаф' : ''}}</p>
        </div>

        <v-menu
          key="text"
          rounded="lg"
          offset-y
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
              color="red"
              @click="addProject(true, row.id)"
              dense
            >
              <v-list-item-title>Добавить проект</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div
          class="outsourcing"
          v-if="row.outsourcing"
        ></div>
      </div>
      <div class="item-position">{{row.position}}</div>
      <div class="items-list">
        <div
          class="items-list__item"
          v-for="item in row.items"
          :key="item.title"
          :class="{full: !isSubItem, over: item.value > 8, equel: item.value === 8,
             'day-off': item.dayOff}"
          :style="{background: item.value < 8 && item.value ? `linear-gradient(to top,
             #BDCBF0 ${item.value*100/8}%, white ${item.value*100/8}%)` : ''}"
        >
          <p v-if="item.value">{{item.value}}</p>
          <v-img
            v-else-if="item.vacation"
            :src="vacationImg"
            contain
            max-width="20px"
          ></v-img>
        </div>
      </div>
      <div
        class="item-total"
        :class="{full: !isSubItem}"
      >{{row.total}}</div>
      <div class="item-deviation">{{row.deviation}}</div>
    </div>

    <template v-if="isSubItem">
      <div
        class="body-subitem"
        v-for="project in row.projects"
        :key="project.title"
      >
        <div class="item-name item-name__sub">
          <p>{{project.title}}</p>

          <v-menu
            key="text"
            rounded="lg"
            offset-y
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                color="red"
                dense
                @click="$emit('setTime', true, project.title)"
              >
                <v-list-item-title>Установить часы</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

        </div>
        <div class="item-position"></div>
        <div class="items-list">
          <div
            class="items-list__item"
            v-for="prItem in project.items"
            :key="prItem.title"
            @click="choiceItem(prItem)"
            :class="{selected: selectedItems.includes(prItem), 'day-off': prItem.dayOff}"
          >
            <p v-if="prItem.value">{{prItem.value}}</p>
            <v-img
              v-else-if="prItem.vacation"
              :src="vacationImg"
              contain
              max-width="20px"
            ></v-img>
          </div>
        </div>
        <div class="item-total">{{project.total}}</div>
        <div class="item-deviation">{{project.deviation}}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import vacationImg from '@/assets/img/vacation.png';

export default {
  name: 'TableBodyItem',

  data() {
    return {
      isSubItem: false,
      isDropdown: false,
      selectedItems: [],
      vacationImg,
    };
  },

  computed: {
    ...mapState('staff', ['isOpenAllItem']),
  },

  watch: {
    isOpenAllItem() {
      this.isSubItem = this.isOpenAllItem;
    },
  },

  methods: {
    addProject(val, id) {
      this.$emit('addProjectModal', val, id);
    },
    choiceItem(val) {
      if (this.selectedItems.includes(val)) {
        const el = this.selectedItems.indexOf(val);
        this.selectedItems.splice(el, 1);
      } else {
        this.selectedItems.push(val);
        this.$emit('choiceItem', val);
      }
    },
  },

  props: {
    row: {
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/table";
@import "src/assets/styles/variables";
* {
  font-size: 14px;
  line-height: 17px;
}

.body-item-wrapper {
  height: 50px;
  display: inline-flex;
  align-items: center;
}

.item-name {
  position: relative;

  .user__outsourcing {
    font-size: 12px;
    line-height: 16px;
    color: #aaaaaa;
  }

  .chevron {
    cursor: pointer;
  }

  .v-btn {
    margin-left: auto;
  }

  .outsourcing {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: $orange;
    position: absolute;
    top: 50%;
    transform: translateY(-3px);
    right: 36px;
  }
}

.body-subitem {
  display: inline-flex;
  height: 50px;
}

.item-name__sub {
  padding-left: 51px;
}
</style>
